import React from "react";
import Header from "./header";
import Main from "./main";

const Home = () => {
  return (
    <>
      <Header />
      <Main />
    </>
  );
};

export default Home;
